import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  generatePath,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { Can } from '@gbs-monorepo-packages/auth';
import {
  Roles,
  SidebarItem,
  getRouteFrom,
  getSessionStorage,
  useToast,
} from '@gbs-monorepo-packages/common';

import { SelectCompanies } from '../../components/SelectCompanies';
import { ADMIN_URL, CLIENT_URL } from '../../constants/Env';
import {
  ANALYTICS,
  COMPANIES,
  COMPANY,
  COURSES,
  COURSES_TEMPLATE,
  FOLDERS,
  LOGIN,
  MEMBERS,
  REDIRECT,
  SETTINGS,
} from '../../constants/RoutePaths';
import { EdgeStorageKey } from '../../constants/Storage';
import { useCompanies } from '../../hooks/useCompanies';
import {
  type ICompanyDTO,
  getCompanies as getClients,
} from '../../services/companies';
import { DefaultLayout } from '../DefaultLayout';
import {
  AdminPortalIcon,
  AnalyticsIcon,
  ClientPortalIcon,
  CompaniesIcon,
  CompanyName,
  CoursesIcon,
  DocumentsIcon,
  HeaderChildrenContent,
  Loading,
  LoadingContainer,
  MembersIcon,
  TemplatesIcon,
} from './styles';

const getFirstClient = async () =>
  await getClients({
    filter: '',
    limit: 1,
    page: 1,
  })
    .then((response) => response.data.at(0) ?? null)
    .catch((error) => {
      throw error;
    });
const CoursesRoute = getRouteFrom(COURSES);
const CompaniesRoute = getRouteFrom(COMPANIES);
const SettingsRoute = getRouteFrom(SETTINGS);
const TeammatesRoute = getRouteFrom(MEMBERS);
const TemplatesRoute = getRouteFrom(COURSES_TEMPLATE);
const AnalyticsRoute = getRouteFrom(ANALYTICS);
const FolderRoute = getRouteFrom(FOLDERS);

const titleCompanies = 'Clients';
const titleCourse = 'Courses';
const titleDocuments = 'Documents';
const titleTeammates = 'Users';
const titleTemplateCourse = 'Templates';
const titleAnalytics = 'Analytics';

const RolesCanGoToCompanies = [Roles.ADMIN, Roles.MANAGER];
const RolesCanSelectCompanies = RolesCanGoToCompanies;
const AlmostRoles = [Roles.ADMIN, Roles.MANAGER, Roles.STAFF, Roles.CREW];
const CanSeeCourses = [Roles.VIEWER];
const RolesCanRedirClient = [Roles.STAFF, Roles.CREW];

const ParamRegex = /:\w+/;
export const CompanyLayout = (): JSX.Element => {
  const { addToast } = useToast();
  const { pathname } = useLocation();
  const { selectedCompany, selectCompanyByID, selectCompany } = useCompanies();
  const { companyId = '' } = useParams();
  const [loadingClients, setLoadingClient] = useState(false);
  const navigate = useNavigate();
  const companyIdSelected = selectedCompany?.id ?? 0;

  const handleSelectCompany = useCallback(
    (id: string, client: ICompanyDTO) => {
      const matchCompany = matchPath(
        {
          path: getRouteFrom(COMPANY),
          end: false,
        },
        pathname
      );

      selectCompany(client);
      if (matchCompany) {
        const { pathname: companyPathname, pattern } = matchCompany;
        const newCompanyPathname = pattern.path.replace(ParamRegex, id);

        navigate(pathname.replace(companyPathname, newCompanyPathname));
      }
    },
    [navigate, pathname, selectCompany]
  );

  const {
    CoursesPath,
    TeammatesPath,
    TemplatesPath,
    AnalyticsPath,
    PrivateDocumentPath,
    PublicDocumentPath,
  } = useMemo(() => {
    const companyId = selectedCompany?.id ?? 0;
    if (companyId <= 0 || loadingClients) {
      return {
        companyId,
        CoursesPath: '.',
        SettingsPath: '.',
        TeammatesPath: '.',
        TemplatesPath: '.',
        AnalyticsPath: '.',
        PublicDocumentPath: '.',
        PrivateDocumentPath: '.',
      };
    }
    return {
      companyId,
      CoursesPath: generatePath(CoursesRoute, { companyId }),
      SettingsPath: generatePath(SettingsRoute, { companyId }),
      TeammatesPath: generatePath(TeammatesRoute, { companyId }),
      TemplatesPath: generatePath(TemplatesRoute, { companyId }),
      AnalyticsPath: generatePath(AnalyticsRoute, { companyId }),
      PrivateDocumentPath:
        companyId <= 0
          ? '.'
          : generatePath(FolderRoute, { companyId, typeFolder: 'private' }),
      PublicDocumentPath:
        companyId <= 0
          ? '.'
          : generatePath(FolderRoute, { companyId, typeFolder: 'public' }),
    };
  }, [loadingClients, selectedCompany?.id]);

  useEffect(() => {
    const companyIdNum = parseInt(companyId);
    const selectedCompanyId = selectedCompany?.id ?? 0;

    const clientToSelect =
      isNaN(companyIdNum) && !selectedCompanyId
        ? getSessionStorage<string>(EdgeStorageKey.CLIENT, '')
        : companyIdNum > 0 && selectedCompanyId !== companyIdNum
        ? companyId
        : null;

    if (clientToSelect === '') {
      setLoadingClient(true);
      void getFirstClient()
        .then((client) => {
          if (client) {
            selectCompany(client);
            return;
          }
          addToast({
            title: 'Error on selecting client',
            description:
              'An error occurred. Please try again or contact Platform support.',
            styleType: 'error',
            dataCy: 'select-client-error-toast',
          });
        })
        .catch(() => {
          navigate(getRouteFrom(LOGIN));
          addToast({
            title: 'Error on selecting client',
            description:
              'An error occurred. Please try again or contact Platform support.',
            styleType: 'error',
            dataCy: 'select-client-error-toast',
          });
        })
        .finally(() => {
          setLoadingClient(false);
        });
    } else if (clientToSelect !== null) {
      setLoadingClient(true);
      void selectCompanyByID(clientToSelect)
        .catch(() => {
          addToast({
            title: 'Error on selecting client',
            description:
              'An error occurred. Please try again or contact Platform support.',
            styleType: 'error',
            dataCy: 'select-client-error-toast',
          });
        })
        .finally(() => {
          setLoadingClient(false);
        });
    }
  }, [
    addToast,
    companyId,
    navigate,
    selectCompany,
    selectCompanyByID,
    selectedCompany?.id,
  ]);

  return companyIdSelected > 0 ? (
    <DefaultLayout
      headerChildren={
        <HeaderChildrenContent>
          <Can
            roles={RolesCanSelectCompanies}
            alternativeChildren={
              <CompanyName>{selectedCompany?.name ?? 'Not found'}</CompanyName>
            }
          >
            <SelectCompanies onValueChange={handleSelectCompany} />
          </Can>
        </HeaderChildrenContent>
      }
      sidebarChildren={(isShrunken) => (
        <>
          <Can roles={CanSeeCourses}>
            <SidebarItem
              dataCy={`courses-client${companyId}`}
              icon={<CoursesIcon />}
              isShrunken={isShrunken}
              title={titleCourse}
              type="link"
              to={companyIdSelected > 0 ? CoursesPath : REDIRECT.path}
            />
          </Can>
          <Can roles={AlmostRoles}>
            <SidebarItem
              dataCy={`courses-client${companyId}`}
              icon={<CoursesIcon />}
              isShrunken={isShrunken}
              title={titleCourse}
              type="link"
              to={companyIdSelected > 0 ? CoursesPath : REDIRECT.path}
            />
            <SidebarItem
              accordionValue="documents"
              dataCy="documents-button"
              icon={<DocumentsIcon />}
              isShrunken={isShrunken}
              title={titleDocuments}
              to={PublicDocumentPath}
              type="accordionLink"
            >
              <SidebarItem
                dataCy="private-document-button"
                isShrunken={isShrunken}
                title={'Private GBS Documents'}
                to={PrivateDocumentPath}
                type="link"
              />
              <SidebarItem
                dataCy="public-document-button"
                isShrunken={isShrunken}
                title={'Public Employee Documents'}
                to={PublicDocumentPath}
                type="link"
              />
            </SidebarItem>
            <SidebarItem
              dataCy={`members-client${companyId}`}
              icon={<MembersIcon />}
              isShrunken={isShrunken}
              title={titleTeammates}
              type="link"
              to={companyIdSelected > 0 ? TeammatesPath : REDIRECT.path}
            />
            <SidebarItem
              dataCy={`templates-client${companyId}`}
              icon={<TemplatesIcon />}
              isShrunken={isShrunken}
              title={titleTemplateCourse}
              type="link"
              to={companyIdSelected > 0 ? TemplatesPath : REDIRECT.path}
            />
            <SidebarItem
              dataCy={`analytics-client${companyId}`}
              icon={<AnalyticsIcon />}
              isShrunken={isShrunken}
              title={titleAnalytics}
              type="link"
              to={companyIdSelected > 0 ? AnalyticsPath : REDIRECT.path}
            />
          </Can>
        </>
      )}
      sidebarFooterChildren={(isShrunken) => (
        <>
          <Can roles={RolesCanGoToCompanies}>
            <SidebarItem
              dataCy={`${CompaniesRoute}`}
              icon={<CompaniesIcon />}
              isShrunken={isShrunken}
              title={titleCompanies}
              type="link"
              to={companyIdSelected > 0 ? CompaniesRoute : REDIRECT.path}
              end
            />
            <SidebarItem
              dataCy={`navLink-dashboardAdmin`}
              icon={<AdminPortalIcon />}
              isShrunken={isShrunken}
              title={'Admin Portal'}
              type="link"
              to={
                ADMIN_URL +
                '/redirect-login' +
                (selectedCompany !== null
                  ? '?selectedClientId=' + selectedCompany.id.toString()
                  : '')
              }
            />
          </Can>
          <Can roles={RolesCanRedirClient}>
            <SidebarItem
              dataCy={`navLink-dashboardAdmin`}
              icon={<ClientPortalIcon />}
              isShrunken={isShrunken}
              title={'Client Portal'}
              type="link"
              to={CLIENT_URL + '/redirect-login'}
            />
          </Can>
        </>
      )}
    />
  ) : (
    <LoadingContainer data-cy="loading-company-layout-container">
      <Loading dataCy="loading-company-layout" />
    </LoadingContainer>
  );
};
