import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';

import { useAuth } from '@gbs-monorepo-packages/auth';
import {
  BaseTooltip,
  Roles,
  getRouteFrom,
} from '@gbs-monorepo-packages/common';

import { TEMPLATE_VIEW } from '../../../../constants/RoutePaths';
import { TemplatesType } from '../../../../constants/TemplatesTypes';
import { type ITemplatePageDTO } from '../../../../services/templates';
import {
  ActionButton,
  DeleteIcon,
  EditIcon,
  PreviewIcon,
  PreviewLink,
  TemplateCardActionContainer,
  TemplateCardContainer,
  TemplateCardIcon,
  TemplateCardIconContainer,
  TemplateCardName,
  TemplateCardNameContainer,
  TemplateCardTitleContainer,
} from './styles';

export interface ITemplateCardProps {
  templateData: ITemplatePageDTO;
  showIcon?: boolean;
  iconSize?: 'normal' | 'small';

  onEditClick?: (template: ITemplatePageDTO) => void;
  onDeleteClick?: (template: ITemplatePageDTO) => void;
}

export const TemplateCard = ({
  templateData,
  showIcon = true,
  iconSize = 'normal',
  onEditClick,
  onDeleteClick,
}: ITemplateCardProps): JSX.Element => {
  const { user, getCurrentRole } = useAuth();
  const isAdmin = useMemo(() => {
    const currentRole = getCurrentRole(user?.roles ?? []);
    if (currentRole?.key === Roles.ADMIN) {
      return true;
    }
    return false;
  }, [getCurrentRole, user?.roles]);

  const allowEditAndDelete = useMemo(() => {
    if (templateData.templateOptionsId === TemplatesType.MY_TEMPLATES) {
      return templateData.userCreatedId === user?.id;
    } else {
      return isAdmin || templateData.userCreatedId === user?.id;
    }
  }, [
    isAdmin,
    templateData.templateOptionsId,
    templateData.userCreatedId,
    user?.id,
  ]);
  return (
    <TemplateCardContainer
      data-cy={`template-card-${templateData.id}-container`}
    >
      <TemplateCardTitleContainer data-cy="template-card-title-container">
        {showIcon && (
          <TemplateCardIconContainer data-cy="template-card-icon-container">
            <TemplateCardIcon
              data-cy={`template-card-${templateData.id}-icon`}
            />
          </TemplateCardIconContainer>
        )}

        <TemplateCardNameContainer data-cy="template-card-name-container">
          <TemplateCardName data-cy={`template-card-${templateData.id}-name`}>
            {templateData.title}
          </TemplateCardName>
        </TemplateCardNameContainer>
      </TemplateCardTitleContainer>

      <TemplateCardActionContainer data-cy="template-card-action-container">
        <BaseTooltip message="Preview">
          <PreviewLink
            data-cy="preview-link"
            target="_blank"
            to={generatePath(getRouteFrom(TEMPLATE_VIEW), {
              templateId: templateData.id.toString(),
            })}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <PreviewIcon iconsize={iconSize} />
          </PreviewLink>
        </BaseTooltip>
        {onEditClick && (
          <BaseTooltip message="Edit">
            <ActionButton
              onClick={(e) => {
                e.stopPropagation();

                onEditClick(templateData);
              }}
              data-cy="edit-button"
              isVisible={allowEditAndDelete}
            >
              <EditIcon iconsize={iconSize} />
            </ActionButton>
          </BaseTooltip>
        )}
        {onDeleteClick && (
          <BaseTooltip message="Delete">
            <ActionButton
              onClick={(e) => {
                e.stopPropagation();

                onDeleteClick(templateData);
              }}
              data-cy="delete-button"
              isVisible={allowEditAndDelete}
            >
              <DeleteIcon iconsize={iconSize} />
            </ActionButton>
          </BaseTooltip>
        )}
      </TemplateCardActionContainer>
    </TemplateCardContainer>
  );
};
