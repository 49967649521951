import { useCallback, useEffect, useRef, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  CheckBoxCard,
  ContentPagination,
  FormBase,
  type IPaginationMetaProps,
  Logger,
  SelectData,
  useToast,
} from '@gbs-monorepo-packages/common';

import { Card } from '../../../../components/Card';
import { CenteredText } from '../../../../components/CenteredText';
import { FormPaginationModal } from '../../../../components/FormPaginationModal';
import {
  TemplatesType,
  groupsTypes,
  templatesTypes,
} from '../../../../constants/TemplatesTypes';
import { useCompanies } from '../../../../hooks/useCompanies';
import { useDragDrop } from '../../../../hooks/useDragDrop';
import {
  type ICreateTemplateGroupProps,
  type IPaginationTemplatesDTO,
  type ITemplateGroupDTO,
  type ITemplatePageDTO,
  type IUpdateTemplateGroupProps,
  getTemplateGroupPages,
  getTemplatePagesByCompanyId,
} from '../../../../services/templates';
import { Loading, LoadingContainer } from '../../styles';
import { SearchTemplates } from '../SearchTemplates';
import { TemplateCard } from '../TemplateCard';
import {
  CheckBoxGroupCustom,
  Content,
  DroppableContainer,
  Input,
  MainContainer,
  TitleText,
} from './styles';

export enum GroupTemplateModalStep {
  NAMING = 'naming',
  SELECTING_TEMPLATES = 'selectingTemplates',
}

export interface IGroupTemplateModalProps {
  groupTemplateToUpdate: ITemplateGroupDTO | null;
  isLoadingAccept: boolean;
  isUserAdmin: boolean;
  isUserManager: boolean;

  onAcceptCreate?: (groupTemplate: ICreateTemplateGroupProps) => void;
  onAcceptUpdate?: (groupTemplate: IUpdateTemplateGroupProps) => void;
  onDecline: () => void;
}

export interface ITemplatePage extends ITemplatePageDTO {
  index?: number;
  selected?: boolean;
}

export interface ITemplatePageWithOrder extends ITemplatePage {
  order?: number;
}

interface IDroppableProps {
  droppableId: string;
  from: number;
  to: number;
}

export const GroupTemplateModal = ({
  groupTemplateToUpdate,
  isLoadingAccept,
  isUserAdmin,
  isUserManager,
  onAcceptCreate,
  onAcceptUpdate,
  onDecline,
}: IGroupTemplateModalProps): JSX.Element => {
  const { selectedCompany } = useCompanies();
  const { addToast } = useToast();

  const { addDragDropListener, removeDragDropListener } = useDragDrop();

  const refFormModalContent = useRef<HTMLDivElement>(null);
  const [currentStep, setCurrentStep] = useState<GroupTemplateModalStep>(
    GroupTemplateModalStep.NAMING
  );
  const [groupTemplateTitle, setGroupTemplateTitle] = useState('');
  const [groupTemplateOption, setGroupTemplateOption] = useState<number>(
    TemplatesType.MY_TEMPLATES
  );
  const [templates, setTemplates] = useState<Map<number, ITemplatePage>>(
    new Map()
  );
  const [templatesPaginationMeta, setTemplatesPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);
  const [selectedTemplates, setSelectedTemplates] = useState<
    ITemplatePageWithOrder[]
  >([]);
  const [selectedTemplatesType, setSelectedTemplatesType] = useState<number>(
    !isUserAdmin && !isUserManager
      ? TemplatesType.MY_TEMPLATES
      : TemplatesType.GLOBAL_TEMPLATES
  );
  const [templatesSearchData, setTemplatesSearchData] = useState('');
  const lastTemplatesSearchData = useRef('');
  const [isSearchingTemplates, setIsSearchingTemplates] = useState(false);

  const courseTemplatesOptionsFiltered = groupsTypes;

  const courseTemplateOptionsStaff = groupsTypes.filter(
    (i) => i.key === TemplatesType.MY_TEMPLATES
  );

  const courseTemplateOptionsManager = groupsTypes.filter(
    (i) =>
      i.key === TemplatesType.MY_TEMPLATES ||
      i.key === TemplatesType.SHARE_TEMPLATES
  );

  const templatesOptionsFiltered = templatesTypes.filter(
    (i) => i.key !== TemplatesType.BUILD_SCRATCH
  );

  const templateOptionsStaff = templatesTypes.filter(
    (i) => i.key === TemplatesType.MY_TEMPLATES
  );

  const templateOptionsManager = templatesTypes.filter(
    (i) =>
      i.key === TemplatesType.MY_TEMPLATES ||
      i.key === TemplatesType.SHARE_TEMPLATES
  );

  const DroppableId = 'template-course-droppable';

  useEffect(() => {
    if (groupTemplateToUpdate) {
      setGroupTemplateTitle(groupTemplateToUpdate.title);
      setGroupTemplateOption(groupTemplateToUpdate.templateOptions.id);

      void getTemplateGroupPages({
        templateGroupId: groupTemplateToUpdate.id,
      }).then((response) => {
        const templatesWithOrder = response.data.map((template) => {
          const order = template.courseTemplatePageOrders?.find(
            (groupsOrder) => {
              return groupsOrder.courseTemplate.id === groupTemplateToUpdate.id;
            }
          );

          return {
            ...template,
            selected: true,
            order: order ? order.pageOrder : 0,
          };
        });

        templatesWithOrder.sort((a, b) => {
          return a.order - b.order;
        });

        setSelectedTemplates(templatesWithOrder);
      });
    }
  }, [groupTemplateToUpdate]);

  const handleNextStep = useCallback(() => {
    if (groupTemplateTitle === '') {
      addToast({
        title: 'Error while saving the template',
        description: 'The title is empty.',
        styleType: 'error',
        dataCy: 'save-template-description-error-toast',
      });
      return;
    }

    setCurrentStep(GroupTemplateModalStep.SELECTING_TEMPLATES);
  }, [addToast, groupTemplateTitle]);

  const handleVerifyAction = useCallback(() => {
    if (groupTemplateToUpdate) {
      if (!onAcceptUpdate) {
        Logger.error(
          'Could not find the required operation to update the group template'
        );
        return;
      }

      onAcceptUpdate({
        id: groupTemplateToUpdate.id,
        title: groupTemplateTitle,
        templateOptions: groupTemplateOption,
        pageTemplates: selectedTemplates.map((template) => template.id),
      });
    } else {
      if (!onAcceptCreate) {
        Logger.error(
          'Could not find the required operation to create the group template'
        );
        return;
      }

      onAcceptCreate({
        title: groupTemplateTitle,
        client: selectedCompany?.id ?? 0,
        templateOptions: groupTemplateOption,
        pageTemplates: selectedTemplates.map((template) => template.id),
      });
    }
  }, [
    groupTemplateToUpdate,
    onAcceptUpdate,
    onAcceptCreate,
    groupTemplateTitle,
    selectedCompany?.id,
    groupTemplateOption,
    selectedTemplates,
  ]);

  const handlePreviousStep = useCallback(() => {
    setCurrentStep(GroupTemplateModalStep.NAMING);
  }, []);

  const searchCourseTemplateData = useCallback(
    async (incrementPage = false) => {
      setIsSearchingTemplates(true);

      let currentPage = 1;

      if (incrementPage && templatesPaginationMeta) {
        currentPage = templatesPaginationMeta.page + 1;
      }

      await getTemplatePagesByCompanyId({
        clientId: selectedCompany?.id ?? 0,
        templateOptionsId: selectedTemplatesType,
        page: currentPage,
        limit: 15,
        filter: templatesSearchData,
      })
        .then((data: IPaginationTemplatesDTO) => {
          const newTemplates = new Map<number, ITemplatePage>(
            incrementPage ? templates : new Map()
          );

          data.data.forEach((template) => {
            newTemplates.set(template.id, {
              ...template,
              selected: false,
            });
          });

          selectedTemplates.forEach((template) => {
            if (!newTemplates.has(template.id)) {
              newTemplates.set(template.id, { ...template, selected: true });
            } else {
              const currentTemplate = newTemplates.get(template.id);

              if (currentTemplate) {
                newTemplates.set(template.id, {
                  ...currentTemplate,
                  selected: true,
                });
              }
            }
          });

          setTemplates(newTemplates);
          setTemplatesPaginationMeta(data.meta);
        })
        .catch((err) => {
          Logger.error('Failed to search the Templates', err);
        })
        .finally(() => {
          setIsSearchingTemplates(false);
        });
    },
    [
      templatesPaginationMeta,
      selectedCompany?.id,
      selectedTemplatesType,
      templatesSearchData,
      templates,
      selectedTemplates,
    ]
  );

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (templatesSearchData.trim() !== lastTemplatesSearchData.current) {
      lastTemplatesSearchData.current = templatesSearchData;

      if (templatesSearchData.trim() === '') {
        void searchCourseTemplateData();
      } else {
        timer = setTimeout(() => {
          void searchCourseTemplateData();
        }, 1000);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [templatesSearchData]);

  useEffect(() => {
    if (currentStep === GroupTemplateModalStep.SELECTING_TEMPLATES) {
      void searchCourseTemplateData();
    }
  }, [currentStep, selectedTemplatesType]);

  const handleSelectTemplate = (data: ITemplatePage) => {
    setSelectedTemplates((prevSelectedTemplates) => {
      prevSelectedTemplates.push({
        ...data,
        order: prevSelectedTemplates.length,
      });

      return prevSelectedTemplates;
    });

    setTemplates((prev) => {
      const updatedMap = new Map<number, ITemplatePage>(prev);

      if (updatedMap.has(data.id)) {
        const template = updatedMap.get(data.id);

        if (template) {
          updatedMap.set(data.id, { ...template, selected: true });
        }
      }
      return updatedMap;
    });
  };

  const handleDeselectTemplate = (data: ITemplatePage) => {
    setSelectedTemplates((prevSelectedTemplates) => {
      const itemIndex = prevSelectedTemplates.findIndex((template) => {
        return template.id === data.id;
      });

      if (itemIndex === -1) {
        return prevSelectedTemplates;
      }

      const updatedTemplates = [
        ...prevSelectedTemplates.slice(0, itemIndex),
        ...prevSelectedTemplates.slice(itemIndex + 1),
      ];

      return updatedTemplates;
    });

    setTemplates((prevTeste) => {
      const updatedMap = new Map(prevTeste);

      if (updatedMap.has(data.id)) {
        const template = updatedMap.get(data.id);

        if (template) {
          updatedMap.set(data.id, { ...template, selected: false });
        }
      }
      return updatedMap;
    });
  };

  useEffect(() => {
    const handleValueChange = ({ droppableId, from, to }: IDroppableProps) => {
      if (droppableId !== DroppableId) return;

      setSelectedTemplates((prevSelectedTemplates) => {
        const sortedTemplates = prevSelectedTemplates.sort(
          (a, b) => (a.order ?? 0) - (b.order ?? 0)
        );

        const [movedItem] = sortedTemplates.splice(from, 1);

        sortedTemplates.splice(to, 0, movedItem);

        const updatedTemplates: ITemplatePageWithOrder[] =
          sortedTemplates.reduce<ITemplatePageWithOrder[]>(
            (acc, template, index) => {
              acc.push({ ...template, order: index + 1 });
              return acc;
            },
            []
          );

        return updatedTemplates;
      });
    };

    addDragDropListener(DroppableId, handleValueChange);

    return () => {
      removeDragDropListener(DroppableId);
    };
  }, [addDragDropListener, removeDragDropListener, DroppableId]);

  const formLayoutParams = {
    naming: {
      acceptText: 'Next',
      declineText: 'Cancel',
      onAccept: handleNextStep,
      onDecline,
      fullWidth: false,
      vhHeight: '',
    },
    selectingTemplates: {
      acceptText: groupTemplateToUpdate
        ? 'Update Course Template'
        : 'Create Course Template',
      declineText: 'Back',
      onAccept: handleVerifyAction,
      onDecline: handlePreviousStep,
      fullWidth: true,
      vhHeight: '70vh',
    },
  };

  return (
    <FormPaginationModal
      mainText={
        groupTemplateToUpdate ? 'Update Course Template' : 'New Course Template'
      }
      acceptText={formLayoutParams[currentStep].acceptText}
      declineText={formLayoutParams[currentStep].declineText}
      onAccept={formLayoutParams[currentStep].onAccept}
      onDecline={formLayoutParams[currentStep].onDecline}
      onOpenChange={onDecline}
      fullWidth={formLayoutParams[currentStep].fullWidth}
      vhHeight={formLayoutParams[currentStep].vhHeight}
      disabled={
        (currentStep === GroupTemplateModalStep.NAMING &&
          groupTemplateTitle === '') ||
        (currentStep === GroupTemplateModalStep.SELECTING_TEMPLATES &&
          selectedTemplates.length === 0)
      }
      open
      loading={isLoadingAccept}
      refContent={refFormModalContent}
    >
      {currentStep === GroupTemplateModalStep.NAMING && (
        <FormBase.Content data-cy="group-template-modal-title-form">
          <FormBase.InputContent
            dataCy="group-template-modal-label-title"
            label="Course Template Name"
            filled={groupTemplateTitle !== ''}
            inputRef="group-name"
          >
            <Input
              data-cy="group-template-modal-input-title"
              value={groupTemplateTitle}
              id="group-name"
              onChange={(e) => {
                if (e.target.value.length <= 50) {
                  const value = e.target.value.replace(/\s\s+/g, ' ');
                  setGroupTemplateTitle(value);
                }
              }}
              required
              type="text"
            />
          </FormBase.InputContent>
          <FormBase.InputContent
            dataCy="group-template-modal-label-options"
            filled
            label="Course Template Options"
            inputRef="group-type"
            isSelectData
          >
            <SelectData
              data={
                isUserAdmin
                  ? courseTemplatesOptionsFiltered
                  : isUserManager
                  ? courseTemplateOptionsManager
                  : courseTemplateOptionsStaff
              }
              onValueChange={(id) => {
                setGroupTemplateOption(Number(id));
              }}
              value={groupTemplateOption.toString()}
              dataCy="group-template-modal-options"
            />
          </FormBase.InputContent>
        </FormBase.Content>
      )}

      {currentStep === GroupTemplateModalStep.SELECTING_TEMPLATES && (
        <>
          <TitleText data-cy="text-selectTemplates">
            Select the option of template you would like to use.
            {selectedTemplates.length > 0 &&
              ` (${selectedTemplates.length} selected)`}
          </TitleText>

          <MainContainer>
            <SearchTemplates
              dataCyPrefix="group-template-modal"
              labelPrefix="Template"
              templateTypeData={
                isUserAdmin
                  ? templatesOptionsFiltered
                  : isUserManager
                  ? templateOptionsManager
                  : templateOptionsStaff
              }
              currentTemplateType={selectedTemplatesType}
              currentSearch={templatesSearchData}
              onChangeTemplateType={(type) => {
                setTemplatesSearchData('');
                setSelectedTemplatesType(type);
              }}
              onChangeSearchData={setTemplatesSearchData}
              onClearSearchData={() => {
                setTemplatesSearchData('');
              }}
              isSearching={isSearchingTemplates}
            />

            <Content>
              <ContentPagination dataCy="group-template-modal-content-templates">
                <CheckBoxGroupCustom
                  type="multiple"
                  dataCy="group-template-modal-checkboxgroup-templates"
                >
                  {templates.size === 0 && !isSearchingTemplates && (
                    <CenteredText
                      message="No templates found"
                      dataCy="pagination-no-templates"
                    />
                  )}

                  {templates.size === 0 && isSearchingTemplates && (
                    <LoadingContainer data-cy="loading-templates-container">
                      <Loading dataCy="loading-templates-label" />
                    </LoadingContainer>
                  )}

                  {templates.size > 0 && (
                    <InfiniteScroll
                      height={200}
                      style={{
                        overflow: 'auto',
                        maxHeight: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                      }}
                      dataLength={templates.size} // This is important field to render the next data
                      next={async () => {
                        await searchCourseTemplateData(true);
                      }}
                      hasMore={
                        templates.size < (templatesPaginationMeta?.total ?? 0)
                      }
                      loader={
                        <LoadingContainer data-cy="loading-templates-container">
                          <Loading dataCy="loading-templates-label" />
                        </LoadingContainer>
                      }
                      endMessage={
                        <CenteredText
                          message="You have seen it all"
                          dataCy="pagination-end-group-templates"
                        />
                      }
                    >
                      {Array.from(templates.values()).map((template) => {
                        return (
                          <CheckBoxCard
                            key={`group-template-template-${template.id}-checkbox`}
                            dataCy={`group-template-template-${template.id}-checkbox`}
                            value={template.id.toString()}
                            onClick={() => {
                              handleSelectTemplate(template);
                            }}
                            disabled={template.selected}
                            defaultChecked={template.selected}
                            data-state={
                              template.selected === true ? 'on' : 'off'
                            }
                            isNotButton
                          >
                            <TemplateCard
                              templateData={template}
                              showIcon={false}
                            />
                          </CheckBoxCard>
                        );
                      })}
                    </InfiniteScroll>
                  )}
                </CheckBoxGroupCustom>
              </ContentPagination>
              <ContentPagination dataCy="group-template-modal-content-selected-templates">
                {selectedTemplates.length === 0 && (
                  <CenteredText
                    message="No templates selected"
                    dataCy="text-no-templates-selected"
                  />
                )}

                {selectedTemplates.length > 0 && (
                  <CheckBoxGroupCustom
                    type="multiple"
                    dataCy="group-template-modal-checkboxgroup-selected-templates"
                  >
                    <ContentPagination dataCy="group-template-modal-content-selected-templates">
                      <Droppable
                        data-cy="group-template-droppable-cards"
                        isDropDisabled={isSearchingTemplates}
                        droppableId={DroppableId}
                      >
                        {(provided) => (
                          <DroppableContainer
                            data-cy="group-template-droppable-cards-container"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {selectedTemplates.map((template, index) => {
                              return (
                                <Card
                                  dataCy={
                                    'group-template-selected-template-card-' +
                                    template.id.toString()
                                  }
                                  key={
                                    'group-template-selected-template-card-' +
                                    template.id.toString()
                                  }
                                  draggableId={template.id}
                                  index={index}
                                  refViewPort={refFormModalContent}
                                  draggable
                                >
                                  <CheckBoxCard
                                    key={`group-template-selected-template-${template.id}-checkbox`}
                                    dataCy={`group-template-selected-template-${template.id}-checkbox`}
                                    value={template.id.toString()}
                                    onClick={() => {
                                      handleDeselectTemplate(template);
                                    }}
                                    data-state="on"
                                    isNotButton
                                  >
                                    <TemplateCard
                                      templateData={template}
                                      showIcon={false}
                                    />
                                  </CheckBoxCard>
                                </Card>
                              );
                            })}
                            {provided.placeholder}
                          </DroppableContainer>
                        )}
                      </Droppable>
                    </ContentPagination>
                  </CheckBoxGroupCustom>
                )}
              </ContentPagination>
            </Content>
          </MainContainer>
        </>
      )}
    </FormPaginationModal>
  );
};
