import { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  ContentPagination,
  type IBaseModalProps,
  SelectData,
} from '@gbs-monorepo-packages/common';

import { CenteredText } from '../../../../components/CenteredText';
import { FilterPagination } from '../../../../components/FilterPagination';
import { FormPaginationModal } from '../../../../components/FormPaginationModal';
import {
  TemplatesType,
  templatesTypes,
} from '../../../../constants/TemplatesTypes';
import {
  type IApiThrowsError,
  type IPaginationMetaProps,
} from '../../../../services/api';
import { type ICreateCourseFromTemplatesProps } from '../../../../services/courses';
import {
  type IPaginationTemplatesDTO,
  type ITemplatePageDTO,
  getTemplatePagesByCompanyId,
} from '../../../../services/templates';
import Logger from '../../../../utils/logger';
import {
  Content,
  CustomCheckBoxGroup,
  Loading,
  LoadingContainer,
  MainContainer,
  SelectContainer,
  SelectTemplateTypeContainer,
  TemplateCardCustom,
  Text,
  TitleText,
} from './styles';

interface IAddPageModalProps
  extends Partial<Omit<IBaseModalProps, 'children' | 'onOpenChange'>> {
  companyId: number;
  onAccept: (
    course: Pick<ICreateCourseFromTemplatesProps, 'pageTemplateIds'>
  ) => Promise<void>;
  onDecline: () => void;
  loading?: boolean;
}
export interface ITemplatePage extends ITemplatePageDTO {
  index?: number;
  selected?: boolean;
  templateOptionsId?: number;
}
const templatesOptionsFiltered = templatesTypes.filter(
  (i) => i.key !== TemplatesType.BUILD_SCRATCH
);

const fromGlobalTemplates = TemplatesType.GLOBAL_TEMPLATES;

export const AddCourseWithTemplateModal = ({
  open = false,
  companyId,
  onAccept,
  onDecline,
  loading,
  ...props
}: IAddPageModalProps): JSX.Element => {
  const isSelectOpen = useRef(new Set());
  const [loadingCreatingCourse, setLoadingCreatingCourse] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const [selectedTemplates, setSelectedTemplates] = useState<ITemplatePage[]>(
    []
  );
  const [templates, setTemplates] = useState<Map<number, ITemplatePage>>(
    new Map()
  );
  const [search, setSearch] = useState<string | null>(null);
  const [templateOptionsId, setTemplateOptionsId] =
    useState<number>(fromGlobalTemplates);
  const loadedTemplatesOnce = useRef(false);
  const refFormModalContent = useRef<HTMLDivElement>(null);
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);
  const noItemsSelected = !Object.keys(selectedTemplates).length;
  const sortedSelectedTemplates = Object.values(selectedTemplates).sort(
    (a, b) => a.title.localeCompare(b.title)
  );

  const checkSelectedTemplate = (id: number) => {
    const templatesArray = Object.values(selectedTemplates);
    const template = templatesArray.find((item) => item.id === id);
    return template !== undefined;
  };

  useEffect(() => {
    loadedTemplatesOnce.current = false;
  }, [companyId]);

  const searchTemplates = (searchByButton = false) => {
    if (!loadingTemplates || searchByButton) {
      setLoadingTemplates(true);
      const pageAux = searchByButton
        ? 0
        : Number((paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0);

      getTemplatePagesByCompanyId({
        clientId: companyId,
        templateOptionsId,
        page: pageAux + 1,
        limit: 10,
        filter: search ?? '',
      })
        .then((response: IPaginationTemplatesDTO) => {
          setPaginationMeta(response.meta);

          const templatesAux: Map<number, ITemplatePage> = !searchByButton
            ? new Map(templates)
            : new Map<number, ITemplatePage>();

          response.data.forEach((item) => {
            const isSelected = checkSelectedTemplate(item.id);

            const template = templatesAux.get(item.id);
            if (template) {
              templatesAux.set(item.id, {
                ...template,
                selected: isSelected,
              });
            } else {
              templatesAux.set(item.id, { ...item, selected: isSelected });
            }
          });

          setTemplates(templatesAux);
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingTemplates(false);
        });
    }
  };

  const handleDeclineAddPage = useCallback(() => {
    if (!isSelectOpen.current.size) {
      setTemplateOptionsId(fromGlobalTemplates);
      setSearch('');
      setSelectedTemplates([]);
      setTemplates(new Map());
      onDecline?.();
    }
  }, [onDecline]);

  const checkTemplate = (data: ITemplatePage) => {
    setSelectedTemplates((prevSelectedTemplates) => ({
      ...prevSelectedTemplates,
      [data.id]: data,
    }));

    setTemplates((prev) => {
      const updatedMap = new Map<number, ITemplatePage>(prev);
      if (updatedMap.has(data.id)) {
        const template = updatedMap.get(data.id);
        if (template) {
          updatedMap.set(data.id, { ...template, selected: true });
        }
      }
      return updatedMap;
    });
  };

  const removeTemplate = (data: ITemplatePage) => {
    setSelectedTemplates((prevSelectedTemplates) => {
      const { [data.id]: _, ...updatedTemplates } = prevSelectedTemplates;
      return updatedTemplates;
    });

    setTemplates((prevTeste) => {
      const updatedMap = new Map(prevTeste);
      if (updatedMap.has(data.id)) {
        const template = updatedMap.get(data.id);
        if (template) {
          updatedMap.set(data.id, { ...template, selected: false });
        }
      }
      return updatedMap;
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (search !== null) {
        searchTemplates(true);
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  useEffect(() => {
    loadedTemplatesOnce.current = false;
  }, [companyId]);

  useEffect(() => {
    loadedTemplatesOnce.current = false;
  }, [templateOptionsId]);

  useEffect(() => {
    let mount = true;

    const loadTemplatesOnce = async () => {
      loadedTemplatesOnce.current = true;
      setLoadingTemplates(true);
      try {
        const result: IPaginationTemplatesDTO =
          await getTemplatePagesByCompanyId({
            clientId: companyId,
            templateOptionsId,
            page: 1,
            limit: 10,
            filter: '',
          });

        if (mount) {
          const templatesAux = new Map<number, ITemplatePage>(templates);

          result.data.forEach((item) => {
            if (!templatesAux.has(item.id)) {
              templatesAux.set(item.id, item);
            }
          });

          setTemplates(templatesAux);
          setPaginationMeta(result.meta);
        }
      } catch (err) {
        loadedTemplatesOnce.current = false;
      } finally {
        setLoadingTemplates(false);
      }
    };

    if (
      (open && companyId && !loadedTemplatesOnce.current) ||
      Array.from(templates.values()).length === 0
    ) {
      void loadTemplatesOnce();
    }

    return () => {
      mount = false;
    };
  }, [templateOptionsId, open, companyId]);

  const handleAddPage = useCallback(async () => {
    setLoadingCreatingCourse(true);
    const pageTemplateIds = Object.keys(selectedTemplates).map(Number);

    await onAccept?.({ pageTemplateIds });
    setLoadingCreatingCourse(false);
  }, [selectedTemplates, onAccept]);

  const handleOpenChange = useCallback((isOpen: boolean, key: string) => {
    if (isOpen) {
      isSelectOpen.current.add(key);
    } else {
      isSelectOpen.current.delete(key);
    }
  }, []);

  return (
    <FormPaginationModal
      {...props}
      open={open}
      acceptText="Create course"
      dataCy="add-course-page-form-modal"
      declineText="Cancel"
      mainText="Create course from template"
      disabled={noItemsSelected}
      loading={loadingCreatingCourse || loading}
      onAccept={() => {
        void handleAddPage();
      }}
      vhHeight="70vh"
      onDecline={handleDeclineAddPage}
      onOpenChange={handleDeclineAddPage}
      refContent={refFormModalContent}
      biggerModal
      fullWidth
    >
      <TitleText data-cy="text-selectTemplates">
        Select the option of template you would like to use.
        {!noItemsSelected &&
          ` (${Object.keys(selectedTemplates).length} selected)`}
      </TitleText>
      <SelectTemplateTypeContainer data-cy="template-content">
        <SelectData
          data={templatesOptionsFiltered}
          defaultValue={String(templateOptionsId)} // Global Template
          placeholder="Choose a template type"
          onValueChange={(id) => {
            setTemplateOptionsId(+id);
            setSelectedTemplates([]);
            setTemplates(new Map());
            setSearch(null);
          }}
          onOpenChange={(isOpen) => {
            handleOpenChange(isOpen, 'template');
          }}
          required
          zIndex={10}
        />
      </SelectTemplateTypeContainer>

      {loadingTemplates && Array.from(templates.values()).length === 0 ? (
        <LoadingContainer data-cy="loading-templates-container">
          <Loading dataCy="loading-templates" />
        </LoadingContainer>
      ) : (
        <MainContainer>
          <FilterPagination
            id="search-template"
            search={search ?? ''}
            placeholder="Search template"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <Content>
            <ContentPagination dataCy="content-templates">
              {loadingTemplates &&
              Array.from(templates.values()).length === 0 ? (
                <LoadingContainer data-cy="loading-templates-container">
                  <Loading dataCy="loading-templates" />
                </LoadingContainer>
              ) : Array.from(templates.values()).length > 0 ? (
                <InfiniteScroll
                  height={200}
                  style={{
                    overflow: 'auto',
                    maxHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  dataLength={Array.from(templates.values()).length} // This is important field to render the next data
                  next={searchTemplates}
                  hasMore={
                    Array.from(templates.values()).length <
                    (paginationMeta?.total ?? 0)
                  }
                  loader={
                    <LoadingContainer data-cy="loading-templates-container">
                      <Loading />
                    </LoadingContainer>
                  }
                  endMessage={
                    <CenteredText
                      message="You have seen it all"
                      dataCy="pagination-endLimit"
                    />
                  }
                >
                  <CustomCheckBoxGroup
                    {...props}
                    type="multiple"
                    data-cy="select-template-container"
                  >
                    {Array.from(templates.values()).map((data) => (
                      <TemplateCardCustom
                        data={data}
                        key={data.id}
                        disabled={data.selected === true || loadingTemplates}
                        data-state={
                          data.selected === false || data.selected === undefined
                            ? 'off'
                            : 'on'
                        }
                        onClick={() => {
                          if (
                            data.selected === false ||
                            data.selected === undefined
                          ) {
                            checkTemplate(data);
                          }
                        }}
                      />
                    ))}
                  </CustomCheckBoxGroup>
                </InfiniteScroll>
              ) : (
                <CenteredText
                  message="No templates found"
                  dataCy="text-noTemplates-found"
                />
              )}
            </ContentPagination>
            <ContentPagination dataCy="content-templates">
              {Object.keys(sortedSelectedTemplates).length > 0 ? (
                <SelectContainer>
                  <CustomCheckBoxGroup
                    {...props}
                    type="multiple"
                    data-cy="select-template-container"
                  >
                    {Object.values(sortedSelectedTemplates).map((data) => (
                      <TemplateCardCustom
                        data={data}
                        key={data.id}
                        data-state="on"
                        onClick={() => {
                          removeTemplate(data);
                        }}
                      />
                    ))}
                  </CustomCheckBoxGroup>
                </SelectContainer>
              ) : (
                <Text data-cy="text-noTemplates-selected">
                  No templates selected
                </Text>
              )}
            </ContentPagination>
          </Content>
        </MainContainer>
      )}
    </FormPaginationModal>
  );
};
